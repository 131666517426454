<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height login-background"
        fluid
      >
        <v-card
          rounded
          elevation="8"
          class="mx-auto text-center"
          min-width="400"
          :loading="loading"
        >
          <img
            src="../../assets/imparfaite-logo.svg"
            alt="Imparfaite Lighthouse"
            width="150"
            class="mt-5"
          >

          <v-card-text class="px-10 pb-0">
            <v-form
              ref="login_form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit="login"
            >
              <v-row dense>
                <v-text-field
                  id="username"
                  v-model="username"
                  :label="$t('views.authentication.login')"
                  :rules="[rules.required]"
                  outlined
                  dense
                  @keyup.enter.prevent="login"
                />
              </v-row>
              <v-row dense>
                <v-text-field
                  id="password"
                  v-model="password"
                  :label="$t('views.authentication.password')"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  @click:append="showPassword = !showPassword"
                  @keyup.enter.prevent="login"
                />
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              class="mt-4"
              :tile="true"
              :disabled="!valid || loading"
              @click.prevent="login"
            >
              {{ $t('views.authentication.connect') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="errorHasOccurred"
      :multi-line="multiLine"
      color="error"
    >
      <v-alert
        type="error"
      >
        <ul>
          <li
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </v-alert>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      multiLine: true,
      username: '',
      password: '',
      showPassword: false,
      loading: false,
      lazy: false,
      valid: true,
      errorHasOccurred: false,
      errors: [],
      rules: {
        required: value => !!value || this.$i18n.t('global.required'),
      },
    }
  },
  computed: {
    ...mapState(['decodedToken']),
    ...mapGetters(['isAdmin', 'isInternalModerator', 'isExternalModerator']),
  },
  methods: {
    async login() {
      this.validate()
      this.loading = true
      await this.axios
        .post('login_check', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.loading = false
          this.$store.commit('login', response.data.token)
          if (this.accessGranted()) {
            this.$router.push({ name: 'Dashboard' })
          } else {
            this.loading = false
            this.errorHasOccurred = true
            this.errors.push('You don\'t have access')
          }
        })
        .catch((error) => {
          this.loading = false
          this.errorHasOccurred = true
          this.errors.push(error)
        })

      return true
    },
    validate() {
      this.errors = []
      this.$refs.login_form.validate()
    },
    accessGranted() {
      return this.isAdmin || this.isInternalModerator || this.isExternalModerator
    },
  },
}
</script>

<style scoped>
.login-background {
    background: no-repeat url("../../assets/login-background-2024.jpg")  center center;
}
</style>
